<template>
  <TryOutProfileForm :bundle="bundle" :bundle-id="bundleId" :show-email-form="true" user-flow-name="purchase_profile">
    <template #buttons="{ onTryClick, onBuyClick, isDisabled }">
      <div class="flex w-full flex-col items-center gap-3">
        <IAIButton
          class="w-full"
          :disabled="isDisabled"
          variant="primary"
          :label="t(`pages.sell-a-profile.next.${bundle.should_show_preview ? 'desktop' : 'mobile'}`)"
          @click="onTryClick"
        />

        <IAIButton
          class="font-medium !text-theme-1"
          variant="link-underlined"
          :label="t('pages.sell-a-profile.just-want-to-buy')"
          :icon-src="Icons.ArrowRight.src"
          icon-side="end"
          @click="onBuyClick"
        />
      </div>
    </template>
  </TryOutProfileForm>
</template>

<script setup lang="ts">
import { t } from "@/locales/vue";
import { IAIButton, Icons } from "@/core-ui-vue";
import TryOutProfileForm from "@/features/sell-a-profile/components/bundle-details/TryOutProfileForm.vue";
import { IBundleDetailesResponse } from "@/features/sell-a-profile/services/api";

defineProps<{
  bundle: IBundleDetailesResponse;
  bundleId: string;
}>();
</script>

<style scoped lang="scss">
.buy-now-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  gap: 4px;
  cursor: pointer;
}
</style>
